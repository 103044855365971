<template>
  <div class="image-full-width">
    <img class="image-full-width__image" :src="src" :alt="alt" :title="title">
  </div>
</template>

<script>
export default {
  name: "ImageFullWidthModule",
  props: {
    src: {
      type: String,
    },
    alt: {
      type: String,
    },
    title: {
      type: String,
    },
  },
};
</script>

<style lang="scss">
.image-full-width {
  .image-full-width__image {
    width: 100%;
    height: auto;
  }
}
</style>
