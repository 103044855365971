<template>
  <main class="home-view" :class="{ 'content-blurred': !backgroundSkipped }">
    <background-component
      :backgroundSkipped="backgroundSkipped"
      @toggleBackground="toggleBackground"
    />
    <section class="section section--intro intro">
      <div class="logo" :class="{ 'logo--faded-out': !backgroundSkipped }">
        <img
          src="/assets/logo/Logo_zwimi_blau.svg"
          alt="Logo Zwischendurch und Mittendrin"
        />
      </div>
      <div class="intro__inner">
        <div class="intro__bubble">
          Wann hast Du das letzte Mal <i>eine Minute</i> am Tag in Dich
          investiert?
        </div>
        <button
          v-if="backgroundSkipped"
          class="button intro__button"
          @click="toggleBackground"
        >
          1140 Minuten
        </button>
      </div>
    </section>
    <section class="section section--background-blue">
      <image-text v-bind="ImageText_1_data" class="image-text--keep-format" />
      <image-text v-bind="ImageText_2_data" class="image-text--keep-format" />
      <image-text v-bind="ImageText_3_data" class="image-text--keep-format" />

      <div class="animation">
        <video class="animation__video" loop autoplay muted>
          <source
            src="/assets/video/animation_1000x1000.mp4"
            type="video/mp4"
          />
          Your browser does not support this video.
        </video>

        <div class="animation__arrow">
          <img src="/assets/icons/Pfeil02.svg" />
        </div>
      </div>

      <image-text v-bind="ImageText_4_data" class="image-text--keep-format" />
      <image-text v-bind="ImageText_5_data" class="image-text--keep-format" />

      <div class="bottom-links">
        <a href="/das-produkt/wahrnehmung" class="button button--secondary"
          >mehr Infos</a
        >
        <a href="/fuer-dein-unternehmen" class="button button--secondary"
          >Anmeldeoptionen</a
        >
      </div>
    </section>
    <section class="section section--quote">
      <quote-component v-bind="Quote_1_data" />
    </section>
  </main>
</template>

<script>
import BackgroundComponent from "@/vue/components/Background.vue";
import ImageText from "@/vue/modules/ImageText.vue";
import QuoteComponent from "@/vue/modules/Quote.vue";

import ImageText_1_data from "@/data/home/image-text_1.json";
import ImageText_2_data from "@/data/home/image-text_2.json";
import ImageText_3_data from "@/data/home/image-text_3.json";
import ImageText_4_data from "@/data/home/image-text_4.json";
import ImageText_5_data from "@/data/home/image-text_5.json";
import Quote_1_data from "@/data/home/quote_1.json";

export default {
  name: "HomeView",
  components: {
    BackgroundComponent,
    ImageText,
    QuoteComponent,
  },
  data() {
    return {
      backgroundSkipped: false,
      ImageText_1_data,
      ImageText_2_data,
      ImageText_3_data,
      ImageText_4_data,
      ImageText_5_data,
      Quote_1_data,
    };
  },
  created() {
    this.backgroundSkipped = sessionStorage.getItem("background-skipped")
      ? true
      : false;
  },
  methods: {
    toggleBackground() {
      this.backgroundSkipped = !this.backgroundSkipped;
      window.scrollTo({ top: 0, behavior: "smooth" });

      sessionStorage.setItem("background-skipped", true);
    },
  },
};
</script>

<style lang="scss">
@import "@/scss/vars.scss";

.home-view {
  .intro {
    padding-top: 80px;

    .logo {
      width: 60%;
      padding-top: 20%;
      margin: 0 auto;
      overflow: hidden;
      position: relative;

      img {
        width: 100%;
        position: absolute;
        left: 0;
        top: -45%;
        opacity: 1;
        transform: scale(1);
        transition: 0.6s cubic-bezier(0.39, 0.34, 0.14, 1.02);
      }

      &.logo--faded-out img {
        opacity: 0;
        transform: scale(0.9);
        transition: 0.15s ease;
      }
    }

    .intro__inner {
      display: flex;
      flex-direction: column;
      gap: 20px;

      @media (max-width: $breakpoint-m) {
        margin-bottom: 70px;
      }

      @media (max-width: $breakpoint-s) {
        margin-bottom: 30px;
      }

      .intro__bubble {
        color: var(--color--blue);
        font-size: 44px;
        font-weight: 500;
        line-height: 54px;
        position: relative;
        max-width: 490px;

        @media (max-width: $breakpoint-s) {
          font-size: 34px;
          line-height: 42px;
        }
      }

      .intro__button {
        width: fit-content;
        margin-left: auto;
        padding: 0.7em 2em;
      }
    }
  }

  //
  // animation
  //

  .animation {
    position: relative;
    margin-bottom: 220px;

    @media (max-width: $breakpoint-s) {
      margin-bottom: 150px;
    }

    .animation__video {
      display: block;
      max-width: 700px;
      margin: 0 auto;
      width: 100%;
    }

    .animation__arrow {
      position: absolute;
      width: 120px;
      bottom: -160px;
      right: 30%;

      @media (max-width: $breakpoint-s) {
        width: 60px;
        bottom: -100px;
        transform: rotate(25deg);
      }

      img {
        width: 100%;
        height: auto;
      }
    }
  }

  //
  // special styles
  //

  .font_headline-montreal--secondary {
    font-size: 44px;
    line-height: 54px;

    @media (max-width: $breakpoint-s) {
      font-size: 34px;
      line-height: 42px;
    }
  }

  p {
    font-size: 20px;
    line-height: 28px;
  }
}
</style>
