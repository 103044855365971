<template>
  <main class="login-view background--skipped">
    <background-component
      :backgroundSkipped="true"
      class="background--secondary"
    />

    <section
      class="section section--overlay section--background-light headline--variant"
    >
      <h2>Anmelden</h2>

      <div class="login__inner">
        <div class="logo__wrapper">
          <div class="logo">
            <img
              src="/assets/logo/Logo_zwimi_blau.svg"
              alt="Logo Zwischendurch und Mittendrin"
            />
          </div>
        </div>
        <label class="login__input">
          E-Mail<br />
          <input type="email" v-model="email" />
        </label>
        <label class="login__input">
          Passwort <br />
          <input type="password" v-model="password" />
        </label>

        <a class="login__forgot" @click.prevent="onClick"
          >Passwort vergessen?</a
        >

        <button class="button login__button" @click="onClick">log in</button>
        <button class="button login__button" @click="onClick">sign up</button>
      </div>
    </section>
  </main>
</template>

<script>
import BackgroundComponent from "@/vue/components/Background.vue";

export default {
  name: "AnmeldenView",
  components: {
    BackgroundComponent,
  },
  data() {
    return {
      email: "",
      password: "",
    };
  },

  methods: {
    onClick() {
      window.alert('Diese Funktion ist aktuell leider noch nicht verfügbar.')
    }
  }
};
</script>

<style lang="scss">
.login-view {
  padding: 200px 0;

  .login__inner {
    display: flex;
    max-width: 300px;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

  .logo__wrapper {
    width: 100%;
    overflow: hidden;
  }

  .logo {
    position: relative;
    width: 100%;
    padding: 20%;

    img {
      width: 100%;
      position: absolute;
      top: -30%;
      left: 0;
    }
  }

  .login__input {
    width: 100%;

    input {
      border: none;
      margin-top: 5px;
      width: 100%;
      border-radius: 100px;
      padding: 6px;
      background-color: var(--color--dark-yellow);
      box-shadow: 2px 2px 12px -8px #000;
    }
  }

  .login__forgot {
    margin-right: auto;
  }

  .login__button {
    margin-left: auto;
    width: 100px;
  }
}
</style>
