<template>
  <div class="page-intro">
    <div class="page-intro__text">
      <h1 class="font_headline-montreal">{{ headline }}</h1>
      <p v-html="copy"></p>
      <a v-for="link in links" :key="link" :href="link.target" class="button">{{
        link.label
      }}</a>
    </div>
    <div v-if="image_src" class="page-intro__image">
      <img :src="image_src" :alt="image_alt" :title="image_title" />
    </div>
  </div>
</template>

<script>
export default {
  setup() {},
  name: "IntroModule",
  props: {
    headline: {
      type: String,
    },
    copy: {
      type: String,
    },
    links: {
      type: Array,
    },
    image_src: {
      type: String,
    },
    image_alt: {
      type: String,
    },
    image_title: {
      type: String,
    },
  },
};
</script>

<style lang="scss">
@import "@/scss/vars.scss";

section > .page-intro {
  margin: 60px auto 150px;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;

  > * {
    width: 46%;

    @media (max-width: $breakpoint-s) {
      width: 100%;
    }
  }

  .page-intro__text {
    max-width: 550px;
    display: flex;
    flex-wrap: wrap;
    gap: 27px;

    p {
      margin-top: 0;
    }
  }

  .page-intro__image {
    img {
      width: 100%;
    }
  }
}
</style>
