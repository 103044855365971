<template>
  <main class="background--skipped">
    <background-component :backgroundSkipped="true" />
    <section class="section">
      <intro v-bind="Intro_data" />
    </section>
    <section
      id="einblick"
      class="section section--overlay section--background-light headline--variant"
    >
      <h2>Einblick</h2>

      <image-text v-bind="ImageText_1_data" />

      <video class="tool-video" width="1000" controls>
        <source src="/assets/video/video_tool.mp4" type="video/mp4" />
        Your browser does not support this video.
      </video>

      <div class="bottom-links">
        <a href="/das-produkt/wahrnehmung" class="button button--secondary"
          >Wahrnehmung</a
        >
        <a href="/das-produkt/gespraeche" class="button button--secondary"
          >Gespräche</a
        >
        <a href="/das-produkt/unterstuetzung" class="button button--secondary"
          >Unterstützung</a
        >
      </div>
    </section>
    <section
      id="zwei-phasen"
      class="section section--overlay section--background-blue headline--variant"
    >
      <h2>zwei Phasen</h2>

      <image-text v-bind="ImageText_2_data" />
      <image-text v-bind="ImageText_3_data" />
      <image-text v-bind="ImageText_4_data" />
      <image-text v-bind="ImageText_5_data" />
      <image-full-width v-bind="ImageFullWidth_1_data" />

      <div class="bottom-links">
        <a href="/das-produkt/wahrnehmung" class="button button--secondary">
          Wahrnehmung
        </a>
        <a href="/das-produkt/gespraeche" class="button button--secondary">
          Gespräche
        </a>
        <a href="/das-produkt/gespraeche" class="button button--secondary">
          Expert:innen Map
        </a>
      </div>
    </section>
    <section class="section section--quote">
      <quote-component v-bind="Quote_1_data" />
    </section>
  </main>
</template>

<script>
import BackgroundComponent from "@/vue/components/Background.vue";
import Intro from "@/vue/modules/Intro.vue";
import ImageText from "@/vue/modules/ImageText.vue";
import ImageFullWidth from "@/vue/modules/ImageFullWidth.vue";
import QuoteComponent from "@/vue/modules/Quote.vue";

import Intro_data from "@/data/tool/intro.json";
import ImageText_1_data from "@/data/tool/image-text_1.json";
import ImageText_2_data from "@/data/tool/image-text_2.json";
import ImageText_3_data from "@/data/tool/image-text_3.json";
import ImageText_4_data from "@/data/tool/image-text_4.json";
import ImageText_5_data from "@/data/tool/image-text_5.json";
import ImageFullWidth_1_data from "@/data/tool/image-full-width_1.json";
import Quote_1_data from "@/data/tool/quote_1.json";

export default {
  name: "ToolView",
  components: {
    BackgroundComponent,
    Intro,
    QuoteComponent,
    ImageText,
    ImageFullWidth,
  },
  data() {
    return {
      Intro_data,
      ImageText_1_data,
      ImageText_2_data,
      ImageText_3_data,
      ImageText_4_data,
      ImageText_5_data,
      ImageFullWidth_1_data,
      Quote_1_data,
    };
  },
};
</script>

<style lang="scss">
.intro {
  color: var(--color--blue);
}

.tool-video {
  display: block;
}
</style>
