<template>
  <div class="background">
    <button
      v-if="!backgroundSkipped"
      class="button background__button"
      @click="toggleBackground"
    >
      entdecken
    </button>
    <div
      class="background__inner"
      :style="backgroundStyles"
      @click="toggleBackground"
    >
      1440 Minuten haben wir am Tag.<br />
      492 Minuten am Tag arbeiten wir in einem Vollzeitjob.<br />
      8,9 Minuten am Tag verbringen wir vor Ampeln.<br />
      231 Minuten am Tag sprechen wir.<br />
      144 Minuten am Tag nutzen wir soziale Medien.<br />
      38,6 Minuten am Tag kochen wir und schmieren Brote.<br />
      23,8 Minuten am Tag reinigen wir unsere eigene Wohnung.<br />
      62,4 Minuten am Tag lesen wir.<br />
      66 Minuten am Tag nutzen wir den Laptop.<br />
      17,8 Minuten am Tag sind wir auf der Suche nach Dingen.<br />
      418 Minuten am Tag nutzen wir für audiovisuelle Medien.<br />
      433,8 Minuten am Tag sollten wir schlafen.<br />
      14,7 Minuten am Tag nutzen wir Printmedien.<br />
      25,7 Minuten am Tag machen wir Sport.<br />
      31 Minuten am Tag verbringen wir im öffentlichen Nahverkehr auf dem Weg
      zur Arbeit.<br />
      96 Minuten am Tag nutzen wir das Smartphone.<br />
      62,4 Minuten am Tag verbringen wir mit Essen.<br />
      24 Minuten am Tag nutzen wir das Tablet. <br />
      396 Minuten am Tag sitzen wir.<br />
      26,35 Minuten am Tag verbringen wir im Bad.<br />
      62 Minuten am Tag telefonieren wir.<br />
      46 Minuten am Tag nutze wir, um über E-Mails zu kommunizieren.<br />
      35 Minuten am Tag nutzen wir, um über SMS oder Messenger zu
      kommunizieren.<br />
      2,7 Minuten am Tag nutzen wir für Online-Kartendienste.<br />
      35 Minuten am Tag gehen wir einkaufen.<br />
      567 Minuten am Tag nutzen wir Medien.<br />
      0,37 Minuten am Tag binden wir uns die Schuhe zu.<br />
      3,7 Minuten am Tag verbringen wir mit Küssen.<br />
      4,45 Minuten am Tag putzen wir uns die Zähne.<br />
      5,2 Minuten am Tag verbringen wir im Verkehrsstau.<br />
      11,5 Minuten am Tag verbringen wir damit uns anzuziehen.<br />
      26,7 Minuten am Tag waschen und pflegen wir unseren Körper.<br />
      35,7 Minuten am Tag arbeiten wir im Garten.<br />
      53,5 Minuten am Tag verbringen wir in Konferenzen.<br />
      62,4 Minuten am Tag verbringen wir mit Fernsehen.<br />
      89,1 Minuten am Tag warten wir in Schlangen.<br />
      98 Minuten am Tag verbringen wir mit Hausarbeit.<br />
      433,8 Minuten am Tag schlafen wir.<br />
      50,5 Minuten am Tag sprechen wir über Klatsch und Tratsch und machen
      Witze.<br />
      13,4 Minuten am Tag waschen und bügeln wir.<br />
      44,6 Minuten am Tag verbringen wir im Auto.<br />
      17,8 Minuten am Tag verbringen wir im Kino, Theater oder Konzert.<br />
      5,9 Minuten am Tag spielen wir am Computer.<br />
      0,7 Minuten am Tag beten wir.<br />
      <span
        >Wann hast Du das letzte Mal <i>eine Minute</i> am Tag in Dich
        investiert?</span
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "BackgroundComponent",
  emits: ["toggleBackground"],
  // inject: ["scroll"],
  props: {
    backgroundSkipped: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      scroll: 0,
    };
  },
  computed: {
    backgroundStyles() {
      return {
        transform: `translateY(-${this.scroll / 1.5}px)`,
      };
    },
  },
  created() {
    window.addEventListener("scroll", this.requestAnimationFrame, {
      capture: true,
      passive: true,
    });
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.requestAnimationFrame);
  },
  methods: {
    toggleBackground() {
      this.$emit("toggleBackground");
    },

    requestAnimationFrame() {
      window.requestAnimationFrame(this.updateScroll);
    },

    updateScroll() {
      this.scroll = window.scrollY;
      window.requestAnimationFrame(this.updateScroll);
    },
  },
};
</script>

<style lang="scss">
.background {
  position: fixed;
  overflow: hidden;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;

  .background__inner {
    position: relative;
    padding: 0 10%;
    color: var(--color--orange);
    background-color: rgba(255, 255, 255, 0.4);
    font-size: 50px;
    line-height: 56px;
    z-index: 20;
    padding-top: 100px;
    font-weight: 600;
    height: 100%;

    i {
      font-weight: 500;
    }

    span {
      color: var(--color--blue);
    }
  }

  .background__button {
    position: fixed;
    bottom: 40%;
    font-size: 1.2em;
    right: 7.5%;
    z-index: 21;
  }

  &.background--secondary {
    background-color: var(--color--blue);
    top: -15px;
    right: -30px;
    bottom: -15px;
    left: -30px;

    .background__inner {
      color: var(--color--light-yellow);
      background: none;
    }
  }
}
</style>
