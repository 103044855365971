import { createWebHistory, createRouter } from "vue-router";
import HomeView from "@/vue/views/HomeView.vue";
import WahrnehmungView from "@/vue/views/WahrnehmungView.vue";
import GespraecheView from "@/vue/views/GespraecheView.vue";
import UnterstuetzungView from "@/vue/views/UnterstuetzungView.vue";
import ToolView from "@/vue/views/ToolView.vue";
import FuerDeinUnternehmenView from "@/vue/views/FuerDeinUnternehmenView.vue";
import UeberDieEntstehungView from "@/vue/views/UeberDieEntstehungView.vue";
import AnmeldenView from "@/vue/views/AnmeldenView.vue";
import DatenschutzView from "@/vue/views/DatenschutzView.vue";
import ImpressumView from "@/vue/views/ImpressumView.vue";
import GlossarView from "@/vue/views/GlossarView.vue";
import Error404View from "@/vue/views/Error404.vue";

const routes = [
	{
		path: "/",
		name: "Home",
		component: HomeView,
		meta: {
			title: 'zwischendurch und mittendrin',
			metaTags: [
				{
					name: 'description',
					content: 'Wann hast Du das letzte Mal eine Minute am Tag in Dich investiert?'
				},
				{
					property: 'og:title',
					content: 'zwischendurch und mittendrin'
				},
				{
					property: 'og:description',
					content: 'Wann hast Du das letzte Mal eine Minute am Tag in Dich investiert?'
				}
			]
		}
	},
	{
		path: "/das-produkt/wahrnehmung",
		name: "Wahrnehmung",
		component: WahrnehmungView,
		meta: {
			title: 'zwischendurch und mittendrin',
			metaTags: [
				{
					name: 'description',
					content: 'Wann hast Du das letzte Mal eine Minute am Tag in Dich investiert?'
				},
				{
					property: 'og:title',
					content: 'zwischendurch und mittendrin'
				},
				{
					property: 'og:description',
					content: 'Wann hast Du das letzte Mal eine Minute am Tag in Dich investiert?'
				}
			]
		}
	},
	{
		path: "/das-produkt/gespraeche",
		name: "Gespräche",
		component: GespraecheView,
		meta: {
			title: 'zwischendurch und mittendrin',
			metaTags: [
				{
					name: 'description',
					content: 'Wann hast Du das letzte Mal eine Minute am Tag in Dich investiert?'
				},
				{
					property: 'og:title',
					content: 'zwischendurch und mittendrin'
				},
				{
					property: 'og:description',
					content: 'Wann hast Du das letzte Mal eine Minute am Tag in Dich investiert?'
				}
			]
		}
	},
	{
		path: "/das-produkt/unterstuetzung",
		name: "Unterstützung",
		component: UnterstuetzungView,
		meta: {
			title: 'zwischendurch und mittendrin',
			metaTags: [
				{
					name: 'description',
					content: 'Wann hast Du das letzte Mal eine Minute am Tag in Dich investiert?'
				},
				{
					property: 'og:title',
					content: 'zwischendurch und mittendrin'
				},
				{
					property: 'og:description',
					content: 'Wann hast Du das letzte Mal eine Minute am Tag in Dich investiert?'
				}
			]
		}
	},
	{
		path: "/das-produkt/das-tool",
		name: "Tool",
		component: ToolView,
		meta: {
			title: 'zwischendurch und mittendrin',
			metaTags: [
				{
					name: 'description',
					content: 'Wann hast Du das letzte Mal eine Minute am Tag in Dich investiert?'
				},
				{
					property: 'og:title',
					content: 'zwischendurch und mittendrin'
				},
				{
					property: 'og:description',
					content: 'Wann hast Du das letzte Mal eine Minute am Tag in Dich investiert?'
				}
			]
		}
	},
	{
		path: "/fuer-dein-unternehmen",
		name: "Für-Dein-Unternehmen",
		component: FuerDeinUnternehmenView,
		meta: {
			title: 'zwischendurch und mittendrin',
			metaTags: [
				{
					name: 'description',
					content: 'Wann hast Du das letzte Mal eine Minute am Tag in Dich investiert?'
				},
				{
					property: 'og:title',
					content: 'zwischendurch und mittendrin'
				},
				{
					property: 'og:description',
					content: 'Wann hast Du das letzte Mal eine Minute am Tag in Dich investiert?'
				}
			]
		}
	},
	{
		path: "/ueber-die-entstehung",
		name: "Über-die-Entstehung",
		component: UeberDieEntstehungView,
		meta: {
			title: 'zwischendurch und mittendrin',
			metaTags: [
				{
					name: 'description',
					content: 'Wann hast Du das letzte Mal eine Minute am Tag in Dich investiert?'
				},
				{
					property: 'og:title',
					content: 'zwischendurch und mittendrin'
				},
				{
					property: 'og:description',
					content: 'Wann hast Du das letzte Mal eine Minute am Tag in Dich investiert?'
				}
			]
		}
	},
	{
		path: "/anmelden",
		name: "Anmelden",
		component: AnmeldenView,
		meta: {
			title: 'zwischendurch und mittendrin',
			metaTags: [
				{
					name: 'description',
					content: 'Wann hast Du das letzte Mal eine Minute am Tag in Dich investiert?'
				},
				{
					property: 'og:title',
					content: 'zwischendurch und mittendrin'
				},
				{
					property: 'og:description',
					content: 'Wann hast Du das letzte Mal eine Minute am Tag in Dich investiert?'
				}
			]
		}
	},
	{
		path: "/datenschutz",
		name: "Datenschutz",
		component: DatenschutzView,
		meta: {
			title: 'zwischendurch und mittendrin',
			metaTags: [
				{
					name: 'description',
					content: 'Wann hast Du das letzte Mal eine Minute am Tag in Dich investiert?'
				},
				{
					property: 'og:title',
					content: 'zwischendurch und mittendrin'
				},
				{
					property: 'og:description',	
					content: 'Wann hast Du das letzte Mal eine Minute am Tag in Dich investiert?'
				}
			]
		}
	},
	{
		path: "/impressum",
		name: "Impressum",
		component: ImpressumView,
		meta: {
			title: 'zwischendurch und mittendrin',
			metaTags: [
				{
					name: 'description',
					content: 'Wann hast Du das letzte Mal eine Minute am Tag in Dich investiert?'
				},
				{
					property: 'og:title',
					content: 'zwischendurch und mittendrin'
				},
				{
					property: 'og:description',
					content: 'Wann hast Du das letzte Mal eine Minute am Tag in Dich investiert?'
				}
			]
		}
	},
	{
		path: "/glossar",
		name: "Glossar",
		component: GlossarView,
		meta: {
			title: 'zwischendurch und mittendrin',
			metaTags: [
				{
					name: 'description',
					content: 'Wann hast Du das letzte Mal eine Minute am Tag in Dich investiert?'
				},
				{
					property: 'og:title',
					content: 'zwischendurch und mittendrin'
				},
				{
					property: 'og:description',
					content: 'Wann hast Du das letzte Mal eine Minute am Tag in Dich investiert?'
				}
			]
		}
	},
];

const router = createRouter({
	history: createWebHistory(),
	routes,
});

router.beforeEach((to, from, next) => {
	window.scrollTo({ top: 0, behavior: "smooth" });

	if (to.matched.length < 1) {
		next(false);
		router.addRoute({ path: to.path, component: Error404View });
		router.push(to.path);
	} else {
		const meta = to.matched[0].meta
		document.querySelector('title').innerText = meta.title ?? ''

		document.querySelectorAll('meta')?.forEach(tag => tag.remove())
		if (meta.metaTags) {
			meta.metaTags.forEach(tagData => {
				const tag = document.createElement('meta')
				for (const key in tagData) {
					tag.setAttribute(key, tagData[key])
				}
				document.head.appendChild(tag)
			})
		}

		next();
	}
});

export default router
