<template>
  <div class="cards">
    <article v-for="card in cards" :key="card" class="cards__card">
      <h3 class="cards_headline">
        {{ card.headline }}
      </h3>
      <ul class="cards__list">
        <li
          v-for="bulletPoint in card.list"
          :key="bulletPoint"
          class="cards__bullet-point"
        >
          {{ bulletPoint }}
        </li>
      </ul>
      <p class="cards__copy" v-html="card.text"></p>

      <a
        v-if="card.button_link"
        :href="card.button_link"
        class="cards__button-wrapper"
      >
        <button class="button cards__button">
          {{ card.button_text }}
        </button>
      </a>
    </article>
  </div>
</template>

<script>
export default {
  name: "CardsModule",
  props: {
    cards: {
      type: Object,
    },
  },
};
</script>

<style lang="scss">
@import "@/scss/vars.scss";

.cards {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 90%;
  align-items: flex-start;

  @media (max-width: $breakpoint-m) {
    width: 85%;
    max-width: 550px;
  }

  @media (max-width: $breakpoint-s) {
    max-width: 250px;
  }

  .cards__card {
    border-radius: 18px;
    padding: 20px;
    position: relative;
    transition: transform 0.3s;
    width: 23%;
    box-shadow: 2px 2px 12px -8px #000;

    @media (max-width: $breakpoint-m) {
      width: 46%;
      margin-bottom: 4%;
    }

    @media (max-width: $breakpoint-s) {
      width: 100%;
      margin-bottom: 8%;
    }

    &:hover {
      transform: scale(1.01);
    }
  }

  .cards_headline {
    font-size: 18px;
    font-weight: 700;
  }

  .cards__list {
    border: 1px solid var(--color--blue);
    border-width: 1px 0;
    padding: 14px 0 14px 20px;
    list-style: none;
    min-height: 150px;
  }

  .cards__copy {
    font-size: 14px;
    line-height: 20px;
  }

  .cards__bullet-point {
    font-family: "Eiko";
    font-style: italic;

    &::before {
      content: "-";
      margin-right: 0.2em;
    }
  }

  .cards__button-wrapper {
    margin-left: auto;
    display: block;
    width: fit-content;

    &::before {
      content: "";
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      position: absolute;
    }
  }
}
</style>
