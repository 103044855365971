<template>
  <main class="wahrnehmung-view background--skipped">
    <background-component :backgroundSkipped="true" />
    <section class="section">
      <intro v-bind="Intro_data" />
    </section>
    <section
      id="eine-minute-am-tag"
      class="section section--overlay section--background-light headline--variant"
    >
      <h2>eine Minute am Tag</h2>
      <image-text v-bind="ImageText_1_data" />

      <flip-cards-component v-bind="FlipCards_1_data" />
      <flip-cards-component v-bind="FlipCards_2_data" />
      <image-text v-bind="ImageText_4_data" />
      <image-full-width v-bind="ImageFullWidth_1_data" />

      <image-text v-bind="ImageText_5_data" />
    </section>
    <section
      id="im-kollektive"
      class="section section--background-blue headline--variant"
    >
      <h2>im Kollektiv</h2>
      <image-text v-bind="ImageText_2_data" />
      <image-text v-bind="ImageText_3_data" />
    </section>
    <section class="section section--quote">
      <quote-component v-bind="Quote_1_data" />
    </section>
  </main>
</template>

<script>
import BackgroundComponent from "@/vue/components/Background.vue";
import ImageText from "@/vue/modules/ImageText.vue";
import ImageFullWidth from "@/vue/modules/ImageFullWidth.vue";
import QuoteComponent from "@/vue/modules/Quote.vue";
import FlipCardsComponent from "@/vue/modules/FlipCards.vue";
import Intro from "@/vue/modules/Intro.vue";

import Intro_data from "@/data/wahrnehmung/intro.json";
import FlipCards_1_data from "@/data/wahrnehmung/flip-cards_1.json";
import FlipCards_2_data from "@/data/wahrnehmung/flip-cards_2.json";
import ImageText_1_data from "@/data/wahrnehmung/image-text_1.json";
import ImageText_2_data from "@/data/wahrnehmung/image-text_2.json";
import ImageText_3_data from "@/data/wahrnehmung/image-text_3.json";
import ImageText_4_data from "@/data/wahrnehmung/image-text_4.json";
import ImageText_5_data from "@/data/wahrnehmung/image-text_5.json";
import ImageFullWidth_1_data from "@/data/wahrnehmung/image-full-width_1.json";
import Quote_1_data from "@/data/wahrnehmung/quote_1.json";

export default {
  name: "WahrnehmungView",
  components: {
    BackgroundComponent,
    QuoteComponent,
    ImageText,
    FlipCardsComponent,
    Intro,
    ImageFullWidth,
  },
  data() {
    return {
      Intro_data,
      FlipCards_1_data,
      FlipCards_2_data,
      ImageText_1_data,
      ImageText_2_data,
      ImageText_3_data,
      ImageText_4_data,
      ImageText_5_data,
      ImageFullWidth_1_data,
      Quote_1_data,
    };
  },
};
</script>

<style lang="scss">
@import "@/scss/vars.scss";

.wahrnehmung-view {
  .image-full-width {
    width: 70%;

    @media (max-width: $breakpoint-s) {
      margin-left: 2%;
      width: 98%;
    }
  }
}
</style>
