<template>
  <main class="error-404-view">
    <background-component :backgroundSkipped="true" />

    <section class="section">
      <div>
        <h1>Error 404</h1>
        <h2>Page not found</h2>
        <a href="/">Back To Home</a>
      </div>
    </section>
  </main>
</template>
<script>
import BackgroundComponent from "@/vue/components/Background.vue";

export default {
  name: "Error404Page",
  components: {
    BackgroundComponent,
  },
};
</script>
<style lang="scss">
// .error-404-view {
//   &::after {
//     content: '';
//     position: absolute;
//     background-color: var(--color--light-yellow);
//     top: 0;
//     right: 0;
//     bottom: 0;
//     left: 0;
//   }
// }
</style>
