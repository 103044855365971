<template>
  <main class="ueber-die-entstehung-view background--skipped">
    <background-component
      :backgroundSkipped="true"
      class="background--secondary"
    />

    <section
      id="beratung"
      class="section section--overlay section--background-light headline--variant"
    >
      <h2>Hey,</h2>
      <image-text v-bind="ImageText_1_data" />
    </section>
    <section
      id="expert:innen-map"
      class="section section--background-blue headline--variant"
    >
      <h2>Kontakt</h2>
      <div class="contacts">
        <div class="contacts__contact">
          <div class="contacts__image">
            <img
              src="/assets/images/Kontakt_Bild_2.png"
              alt="Portät Pia Eltgen"
            />
          </div>
          <div class="contacts__text">
            <h3>Pia Eltgen</h3>
            <p>
              Bachelor: <br />
              Kommunikationsdesign, HS Mainz<br /><br />

              Master: <br />
              Strategische Gestaltung<br />
              HfG Schwäbisch Gmünd<br /><br />

              <a
                :href="`mailto:${mails.pia}?suject=zwischendurch%20und%20mittendrin%20Anfrage`"
                >{{ mails.pia }}</a
              ><br />
              <a href="https://linkedin.com/in/pia-eltgen-03792320b/" target="_blank">linkedin</a>
            </p>
          </div>
        </div>
        <div class="contacts__contact">
          <div class="contacts__image">
            <img
              src="/assets/images/Kontakt_Bild_3.png"
              alt="Portät Zahira Diefenbach"
            />
          </div>
          <div class="contacts__text">
            <h3>Zahira Diefenbach</h3>
            <p>
              Bachelor: <br />
              Industrial Design, HS Pforzheim<br /><br />

              Master: <br />
              Strategische Gestaltung<br />
              HfG Schwäbisch Gmünd<br /><br />

              <a
                :href="`mailto:${mails.zahira}?suject=zwischendurch%20und%20mittendrin%20Anfrage`"
                >{{ mails.zahira }}</a
              ><br />
              <a href="https://linkedin.com/in/zahira-diefenbach/" target="_blank">linkedin</a>
            </p>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import BackgroundComponent from "@/vue/components/Background.vue";
import ImageText from "@/vue/modules/ImageText.vue";

import ImageText_1_data from "@/data/ueber-die-entstehung/image-text_1.json";

export default {
  name: "UeberUnsView",
  components: {
    BackgroundComponent,
    ImageText,
  },
  data() {
    return {
      ImageText_1_data,
      mails: {
        pia: "piaeltgen@googlemail.de",
        zahira: "z.diefenbach@gmail.com",
      },
    };
  },
};
</script>

<style lang="scss">
@import "@/scss/vars.scss";

.ueber-die-entstehung-view {
  padding: 200px 0 50px;

  .image-text {
    margin: -60px auto 20px;
  }
}

.contacts {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  > * {
    width: 48%;

    @media (max-width: $breakpoint-m) {
      width: 100%;
      margin-bottom: 80px;
    }
  }

  .contacts__contact {
    display: flex;
    justify-content: space-between;

    @media (max-width: $breakpoint-m) {
      &:nth-child(even) {
        flex-direction: row-reverse;

        .contacts__text {
          text-align: right;
        }
      }
    }

    > * {
      width: 46%;
    }
  }

  .contacts__image {
    img {
      width: 100%;
    }
  }
}
</style>
