<template>
  <main class="imprint-view background--skipped">
    <background-component
      :backgroundSkipped="true"
      class="background--secondary"
    />

    <section
      class="section section--overlay section--background-light headline--variant"
    >
      <h2>Impressum</h2>

      <div>
        <p>
          zwischendurch und mittendrin GbR <br />
          c/o Zahira Diefenbach und Pia Eltgen<br />
          Uferstraße 48 <br />
          73525 Schwäbisch Gmünd
        </p>
        <p>
          Kontakt:<br />
          <a
            :href="`mailto:${mails.zahira}?suject=zwischendurch%20und%20mittendrin%20Anfrage`"
            >{{ mails.zahira }}</a
          ><br />
          <a
            :href="`mailto:${mails.pia}?suject=zwischendurch%20und%20mittendrin%20Anfrage`"
            >{{ mails.pia }}</a
          ><br />
        </p>
      </div>
    </section>
  </main>
</template>

<script>
import BackgroundComponent from "@/vue/components/Background.vue";

export default {
  name: "ImpressumView",
  components: {
    BackgroundComponent,
  },

  data() {
    return {
      mails: {
        pia: "piaeltgen@googlemail.de",
        zahira: "z.diefenbach@gmail.com",
      },
    };
  },
};
</script>

<style lang="scss">
.imprint-view {
  padding: 200px 0;
}
</style>
