<template>
  <div>
    <header-component :logo="nav_logo" :nav_links="nav_links" />
    <router-view />
    <footer-component />
  </div>
</template>

<script>
import HeaderComponent from "@/vue/components/Header";
import FooterComponent from "@/vue/components/Footer";

import nav_logo from "@/data/header/logo.json";
import nav_links from "@/data/header/nav_links.json";

export default {
  name: "App",
  components: {
    HeaderComponent,
    FooterComponent,
  },
  data() {
    return {
      nav_logo,
      nav_links,
    };
  },
};
</script>

<style lang="scss">
@import "@/scss/base.scss";

main {
  position: relative;
  overflow: hidden;

  &.content-blurred .background ~ * {
    filter: blur(10px);
  }

  &:not(.content-blurred) {
    .background {
      filter: blur(15px);
      z-index: -1;
    }
  }
}

.bottom-links {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  margin: -50px auto 50px auto;
}

i {
  font-family: "Eiko Italic";
}
</style>
