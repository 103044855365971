<template>
  <div class="quote">
    <q class="quote__copy" v-html="quote"></q>
    <p class="quote__author">- {{ author }}</p>
  </div>
</template>

<script>
export default {
  name: "QuoteComponent",
  props: {
    quote: {
      type: String,
      default: "",
    },
    author: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss">
@import "@/scss/vars.scss";

.quote {
  text-align: center;
  color: var(--color--blue);

  .quote__copy {
    font-family: "Eiko";
    font-size: 32px;
    line-height: 44px;
    font-weight: 500;

    @media (max-width: $breakpoint-m) {
      font-size: 28px;
      line-height: 40px;
    }

    @media (max-width: $breakpoint-s) {
      font-size: 24px;
      line-height: 35px;
    }
  }
}
</style>
