import { createApp } from 'vue'
import App from './App.vue'
import router from './js/router'

const addSwipeEvent = (target, callback) => {
	const start = {}

	target.addEventListener('touchstart', (e) => {
		start.x = e.targetTouches[0].clientX
		start.y = e.targetTouches[0].clientY
	}, false);

	target.addEventListener('mousedown', (e) => {
		start.x = e.clientX
		start.y = e.clientY
	}, false);

	target.addEventListener('touchmove', (e) => {
		e.preventDefault();
	}, false)

	target.addEventListener('mousemove', (e) => {
		e.preventDefault();
	}, false)

	target.addEventListener('touchend', (e) => {
		calculateMove(start, e.changedTouches[0])
	}, false);
	
	target.addEventListener('mouseup', (e) => {
		calculateMove(start, e)
	}, false);

	const calculateMove = (start, end) => {
		const event = {
			y: start.y - end.clientY,
			x: start.x - end.clientX
		}
		callback(event)
	}
}

(async () => {
	const app = createApp(App)
	app.config.unwrapInjectedRef = true

	app.provide('addSwipeEvent', addSwipeEvent)

	app.use(router)
	app.mount('#app')
})()
