<template>
  <div class="flip-cards">
    <div
      class="flip-cards__inner"
      :class="{ 'flip-cards__inner--inverted': invert_layout }"
    >
      <div class="flip-cards__text">
        <h3 class="flip-cards__headline font_headline-montreal--secondary">
          {{ headline }}
        </h3>
        <p class="flip-cards__copy">
          {{ copy }}
        </p>
      </div>
      <div ref="swiper" class="flip-cards__cards">
        <div
          class="flip-cards__cards-wrapper"
          :style="{ height: `${300 + cards.length * 20}px` }"
        >
          <article
            v-for="(card, index) in cards"
            :key="index"
            ref="cardsRef"
            :style="getStyles(index)"
            class="flip-cards__card"
            :class="{
              'flip-cards__card--flipped':
                frontCardIsFlipped && cardsOrderFactor[index] === 0,
            }"
            @click="flipCard(index)"
          >
            <div class="flip-cards__card-inner">
              <div class="flip-cards__card-front">
                <img
                  @dragstart="(e) => e.preventDefault()"
                  :src="card.front"
                  :alt="`Wahrnehmung Karte ${index} Cover`"
                />
              </div>
              <div class="flip-cards__card-back">
                <img
                  @dragstart="(e) => e.preventDefault()"
                  :src="card.back"
                  :alt="`Wahrnehmung Karte ${index} Text`"
                />
              </div>
            </div>
          </article>
        </div>
        <div class="flip-cards__controls">
          <button
            @click="changeCard(1)"
            class="button flip-cards__control flip-cards__control--prev"
          ></button>
          <button
            @click="changeCard(-1)"
            class="button flip-cards__control flip-cards__control--next"
          ></button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";

export default {
  setup() {
    const cardsRef = ref(null);
    const swiper = ref(null);

    return {
      cardsRef,
      swiper,
    };
  },

  inject: ["addSwipeEvent"],

  name: "FlipCardsModule",

  props: {
    headline: {
      type: String,
    },
    copy: {
      type: String,
    },
    cards: {
      type: Array,
    },
    invert_layout: {
      type: Boolean,
    },
  },

  data() {
    return {
      frontCardIsFlipped: false,
      cardsOrderFactor: this.cards.map((el, index) => index),
      allowFlip: true,
      timer: null,
    };
  },

  mounted() {
    this.addSwipeEvent(this.swiper, (e) => {
      if (Math.abs(e.x) > 50) {
        const dir = Math.abs(e.x) / e.x;
        this.changeCard(dir);
        this.allowFlip = false;
      }
    });
  },

  methods: {
    getStyles(origIndex) {
      const index = this.cardsOrderFactor[origIndex];
      let bottom,
        right,
        zIndex = `${50 - index}`;

      if (index < 0) {
        bottom = `${this.cards.length * 20 - 30}px`;
        right = `-${220 + 50}px`;
        zIndex = 50 - this.cards.length + index * -1;
      } else if (index > 0) {
        bottom = `${index * 20}px`;
        right = `${(index - 1) * 15}px`;
      } else {
        bottom = `100px`;
        right = `-30px`;
      }

      return {
        bottom,
        right,
        zIndex,
      };
    },

    flipCard(index) {
      if (!this.allowFlip) {
        this.allowFlip = true;
        return;
      }
      if (this.cardsOrderFactor[index] > 0) return;
      this.frontCardIsFlipped = !this.frontCardIsFlipped;
    },

    changeCard(dir) {
      this.frontCardIsFlipped = false;
      this.resetAnimations();

      this.cardsOrderFactor = this.cardsOrderFactor.map((factor, index) => {
        const newFactor = factor + dir;
        const max = this.cards.length - 1;

        if (newFactor > max) {
          this.finishAnimation(0, index);
          return -1;
        }
        if (newFactor < 0) {
          this.finishAnimation(max, index);
          return -1 * (max + 1);
        }

        return newFactor;
      });
    },

    finishAnimation(newValue, index) {
      this.timerZIndex = setTimeout(() => {
        this.cardsOrderFactor = this.cardsOrderFactor.map((val) => {
          if (val >= 0) return val;
          return -1 * val - 1;
        });
      }, 200);
      this.timer = setTimeout(() => {
        this.cardsOrderFactor[index] = newValue;
      }, 400);
    },

    resetAnimations() {
      clearTimeout(this.timer);
      clearTimeout(this.timerZIndex);

      this.cardsOrderFactor = this.cardsOrderFactor.map((val) => {
        if (val >= 0) return val;
        return -1 * val - 1;
      });
    },
  },
};
</script>

<style lang="scss">
@import "@/scss/vars.scss";

section .flip-cards {
  width: 100%;
  max-width: none;
  overflow: hidden;
  margin: 100px auto;

  .flip-cards__inner {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    width: 85%;
    max-width: $max-width;
    margin: 0 auto;
    gap: 30px;

    &.flip-cards__inner--inverted {
      flex-direction: row-reverse;
    }

    > * {
      width: 46%;

      @media (max-width: $breakpoint-s) {
        width: 100%;
      }
    }
  }

  .flip-cards__cards-wrapper {
    position: relative;
    user-select: none;
    width: 213px;
    margin: 0 auto;
  }

  .flip-cards__card {
    position: absolute;
    width: 213px;
    height: 300px;
    perspective: 1000px;
    transition: 0.3s;

    &.flip-cards__card--flipped {
      .flip-cards__card-inner {
        transform: rotateY(180deg) rotateZ(-90deg);
      }
    }
  }

  .flip-cards__card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    box-shadow: 4px 3px 12px -7px #000;
    border-radius: 8px;
  }

  .flip-cards__card-front,
  .flip-cards__card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    border-radius: 8px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  // .flip-cards__card-front {
  // }

  .flip-cards__card-back {
    transform: rotateY(180deg);
  }

  .flip-cards__controls {
    display: flex;
    justify-content: center;
    gap: 10px;
  }

  .flip-cards__control {
    position: relative;
    background-color: transparent;
    border: 2px solid var(--color--orange);
    padding: 12px;

    &::before,
    &::after {
      content: "";
      position: absolute;
      width: 10px;
      height: 2px;
      border-radius: 3px;
      background-color: var(--color--orange);
      transform: rotate(45deg);
      left: 8px;
      top: 8px;
    }
    &::after {
      transform: rotate(-45deg);
      top: 14px;
    }

    &.flip-cards__control--prev {
      transform: rotate(180deg);
    }
  }
}
</style>
