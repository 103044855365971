<template>
  <footer class="footer">
    <router-link to="/impressum">Impressum</router-link>
    <router-link to="/datenschutz">Datenschutz</router-link>
  </footer>
</template>

<script>
export default {
  name: "FooterComponent",
};
</script>

<style lang="scss">
.footer {
  display: flex;
  justify-content: flex-end;
  position: relative;
  z-index: 80;
  gap: 5%;
  background-color: var(--color--light-yellow);
  color: var(--color--orange);
  padding: 20px;
  font-weight: 600;
}
</style>
