<template>
  <main class="fuer-dein-unternehmen-view background--skipped">
    <background-component :backgroundSkipped="true" class="background--secondary"/>
    <section class="section">
      <div class="page-intro">
        <div class="page-intro__logo">
          <div class="page-intro__ratio-box">
            <img
              src="/assets/logo/Logo_zwimi_hell.svg"
              alt="Logo Zwischendurch und Mittendrin"
            />
          </div>
        </div>
        <div class="page-intro__inner">
          <h1 class="font_headline-montreal--secondary">
            {{ Intro_data.headline }}
          </h1>
          <p class="page-intro__copy" v-html="Intro_data.copy"></p>
        </div>
      </div>
    </section>
    <section
      id="alle-optionen"
      class="section section--overlay section--background-light headline--variant"
    >
      <h2>alle Optionen</h2>
      <cards-component v-bind="Cards_1_data" />
    </section>
    <section class="section section--quote">
      <quote-component v-bind="Quote_1_data" />
    </section>
  </main>
</template>

<script>
import BackgroundComponent from "@/vue/components/Background.vue";
import QuoteComponent from "@/vue/modules/Quote.vue";
import CardsComponent from "@/vue/modules/Cards.vue";

import Quote_1_data from "@/data/fuer-dein-unternehmen/quote_1.json";
import Cards_1_data from "@/data/fuer-dein-unternehmen/cards_1.json";
import Intro_data from "@/data/fuer-dein-unternehmen/intro.json";

export default {
  name: "FuerDeinUnternehmenView",
  components: {
    BackgroundComponent,
    QuoteComponent,
    CardsComponent,
  },
  data() {
    return {
      Quote_1_data,
      Cards_1_data,
      Intro_data,
    };
  },
};
</script>

<style lang="scss">
@import "@/scss/vars.scss";

.fuer-dein-unternehmen-view {
  // .background {
  //   background-color: var(--color--blue);
  //   top: -15px;
  //   right: -30px;
  //   bottom: -15px;
  //   left: -30px;
  // }

  // .background__inner {
  //   color: var(--color--light-yellow);
  //   background: none;
  // }

  .quote,
  .quote__author {
    color: var(--color--light-yellow);
  }

  .page-intro {
    gap: 4%;
    align-items: flex-start;

    > * {
      width: 48%;

      @media (max-width: $breakpoint-s) {
        width: 100%;
      }
    }
  }

  .page-intro__inner {
    margin-top: 10%;
  }

  .page-intro__logo {
    padding: 0 2%;
  }

  .page-intro__ratio-box {
    overflow: hidden;
    position: relative;
    padding: 20%;

    img {
      position: absolute;
      top: -30%;
      right: 0;
      bottom: 0;
      right: 0;
    }
  }

  .page-intro__copy {
    color: var(--color--light-yellow);
    width: 90%;
    margin-left: auto;
  }

  .font_headline-montreal--secondary {
    color: var(--color--orange);
    font-size: 45px;
    line-height: 50px;
    margin-bottom: 30px;

    @media (max-width: $breakpoint-m) {
      font-size: 38px;
      line-height: 44px;
    }

    @media (max-width: $breakpoint-s) {
      font-size: 30px;
      line-height: 38px;
    }
  }
}
</style>
