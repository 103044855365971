<template>
  <header class="header">
    <div class="header__inner">
      <a class="header__logo" href="/" title="Home">
        <div class="header__icon"></div>
      </a>
      <nav class="main-menu" :class="{ 'main-menu--toggled': mobileNavOpen }">
        <div
          v-for="(link, index) in nav_links"
          :key="index"
          class="main-menu__link"
          :class="{
            currentPage: link.value === currentPage,
            'main-menu__dropdown-wrapper': link.isDropDown,
          }"
        >
          <router-link
            v-if="!link.isDropDown"
            :to="link.value"
            @click="onNavToggle(false)"
          >
            {{ link.label }}
          </router-link>
          <div
            v-else
            class="main-menu__dropdown"
            :class="{
              'main-menu__dropdown--toggled': toggledNavDropdown === index,
            }"
          >
            <button
              class="button"
              @click="toggleDropdown(index)"
              @blur="toggleDropdown(null)"
            >
              {{ link.label }}
            </button>
            <div class="main-menu__dropdown-content">
              <router-link
                v-for="sub_link in link.values"
                :key="sub_link"
                :to="sub_link.value"
                :class="{ currentPage: sub_link.value === currentPage }"
                @mousedown="onMouseDown(sub_link.value)"
              >
                {{ sub_link.label }}
              </router-link>
            </div>
          </div>
        </div>

        <div
          class="main-menu__link main-menu__link--glossar"
          :class="{
            currentPage: '/glossar' === currentPage,
          }"
        >
          <router-link to="/glossar" @click="onNavToggle(false)">
            Glossar
          </router-link>
        </div>
        <div
          class="main-menu__link main-menu__link--login"
          :class="{
            currentPage: '/anmelden' === currentPage,
          }"
        >
          <router-link to="/anmelden" @click="onNavToggle(false)">
            Anmelden
          </router-link>
        </div>
      </nav>
      <button
        aria-label="Toggle menu"
        class="main-menu__toggle"
        :class="{ 'main-menu__toggle--toggled': mobileNavOpen }"
        @click="onNavToggle()"
      >
        <span></span>
        <span></span>
      </button>
    </div>
  </header>
</template>

<script>
export default {
  name: "HeaderComponent",
  props: {
    nav_links: {
      type: Array,
      default() {
        return [];
      },
    },
    logo: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      mobileNavOpen: false,
      toggledNavDropdown: null,
    };
  },
  computed: {
    currentPage() {
      return this.$route.path;
    },
  },
  methods: {
    onNavToggle(value = null) {
      if (value !== null) {
        this.mobileNavOpen = value;
      } else {
        this.mobileNavOpen = !this.mobileNavOpen;
      }

      const body = document.querySelector("body");

      this.mobileNavOpen
        ? body.classList.add("menu-is-open")
        : body.classList.remove("menu-is-open");
    },
    toggleDropdown(index = null) {
      this.toggledNavDropdown = index;
    },
    onMouseDown(href) {
      this.onNavToggle(false);
      this.$router.push(href);
    },
  },
};
</script>

<style lang="scss">
$header-height: 50px;
$transition-1: 0.3s;
$color-2: var(--color--orange);
$color-3: var(--color--blue);
$color-4: var(--color--light-yellow);
@import "@/scss/vars.scss";

.menu-is-open {
  @media (max-width: $breakpoint-m) {
    overflow-y: hidden;
  }
}

.header {
  position: sticky;
  width: 100%;
  z-index: 90;
  top: 0;
  left: 0;
  right: 0;
  background-color: var(--color--light-yellow);
  color: var(--color--orange);
  transition: top 0.3s ease;
  height: $header-height;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  font-size: 15px;

  .header__inner {
    height: 100%;
    width: 100%;

    @media (max-width: $breakpoint-m) {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .header__logo {
      display: block;
      position: absolute;
      left: 30px;
      top: 15px;
      z-index: 10;

      .header__icon {
        background-color: var(--color--orange);
        height: 20px;
        width: 20px;
        border-radius: 50px;
      }
    }

    .main-menu {
      display: flex;
      align-items: center;
      gap: 32px;
      width: 85%;
      max-width: $max-width;
      margin: 0 auto;
      height: 100%;

      @media (max-width: $breakpoint-m) {
        gap: 0;
      }

      .main-menu__link {
        position: relative;
        font-weight: 600;

        &.main-menu__link--glossar {
          margin-left: auto;
        }

        &.main-menu__link--login {
          a {
            margin: 0 auto;
            padding: 5px 12px;
            border: 1px solid var(--color--orange);
            border-radius: 100px;
          }
        }

        a {
          display: block;
          height: 100%;
        }

        &:not(.main-menu__dropdown-wrapper):hover {
          // font-family: "Eiko";
          // font-style: italic;
          // font-weight: 400;
          // margin: 0 -2px;
          // font-weight: 700;
        }

        &.currentPage {
          font-family: "Eiko";
          font-style: italic;
          font-weight: 400;
        }

        .main-menu__dropdown {
          position: relative;

          button {
            background-color: inherit;
            color: inherit;
            border: none;
            font-size: inherit;
            padding: 0;

            &:hover {
              // font-weight: 600;
            }

            @media (max-width: $breakpoint-m) {
              display: none;
            }
          }

          .main-menu__dropdown-content {
            display: none;
            flex-wrap: wrap;
            position: absolute;
            top: 40px;
            background-color: var(--color--light-yellow);
            color: var(--color--orange);
            margin-left: -38%;
            padding: 6px 28px;
            border-radius: 12px;

            @media (max-width: $breakpoint-m) {
              display: flex;
              position: relative;
              margin: 0;
              top: 5px;
            }

            a {
              padding: 12px 0;
              position: relative;
              width: 100%;

              &:hover {
                // font-family: "Eiko";
                // font-style: italic;
                // font-weight: 400;
                // font-weight: 700;
              }

              &.currentPage {
                font-family: "Eiko";
                font-style: italic;
                font-weight: 400;
              }
            }
          }

          &.main-menu__dropdown--toggled {
            .main-menu__dropdown-content {
              display: flex;
            }
          }
        }
      }

      @media (max-width: $breakpoint-m) {
        position: fixed;
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;
        top: 0;
        right: 0;
        left: 0;
        bottom: 100%;
        height: auto;
        width: 100%;
        max-width: none;
        overflow: hidden;
        transition: $transition-1;
        background-color: $color-4;

        .main-menu__link {
          text-align: center;
          font-size: 2.5em;
          font-size: 26px;
          opacity: 0;
          transform: scale(0.95);
          transition: 0s ease 0s;
          width: 100%;

          &:hover {
            color: inherit;
          }

          &.currentPage {
            &::after {
              opacity: 0;
            }
          }

          a {
            display: none;
          }

          @media (max-width: $breakpoint-s) {
            font-size: 22px;
            line-height: 1.5em;
            width: 100%;
          }
        }

        &.main-menu--toggled {
          bottom: 0px;
          padding-top: 70px;

          .main-menu__link {
            transform: scale(1);
            opacity: 1;
            transition: $transition-1 1.1s;
            transition-property: opacity, transform;

            &.main-menu__link--glossar {
              margin-left: auto;

              @media (max-width: $breakpoint-m) {
                margin-left: auto;
                margin-right: 1%;
                margin-top: 20px;
                max-width: 200px;
                width: 45%;
              }
            }

            &.main-menu__link--login {
              @media (max-width: $breakpoint-m) {
                margin-left: 1%;
                margin-right: auto;
                width: 45%;
                max-width: 200px;
                margin-top: 20px;

                a {
                  padding: 5px 12px;
                }
              }
            }

            &:nth-child(1) {
              transition-delay: 0.2s;
            }

            &:nth-child(2) {
              transition-delay: 0.3s;
            }

            &:nth-child(3) {
              transition-delay: 0.4s;
            }

            &:nth-child(4) {
              transition-delay: 0.5s;
            }

            &:nth-child(5) {
              transition-delay: 0.6s;
            }

            &:nth-child(6) {
              transition-delay: 0.7s;
            }

            &:nth-child(7) {
              transition-delay: 0.8s;
            }

            &:nth-child(8) {
              transition-delay: 0.9s;
            }

            &:nth-child(9) {
              transition-delay: 1.1s;
            }

            a {
              display: block;
              padding: 12px 0;
            }

            &::after {
              height: 4px;
              border-radius: 4px;
              opacity: 1;
            }
          }
        }
      }
    }

    .main-menu__toggle {
      padding: 16px;
      background-color: transparent;
      display: none;
      position: absolute;
      right: 20px;
      border: none;
      cursor: pointer;

      @media (max-width: $breakpoint-m) {
        display: block;
      }

      span {
        position: absolute;
        background-color: $color-2;
        height: 4px;
        left: 10%;
        right: 10%;
        margin-top: -5px;
        border-radius: 4px;
        transition: $transition-1;
      }

      span:last-child {
        margin-top: 3px;
      }

      &.main-menu__toggle--toggled {
        span {
          margin-top: -1px;
          transform: rotateZ(45deg);
        }

        span:last-child {
          transform: rotateZ(-45deg);
        }
      }
    }
  }
}
</style>
