<template>
  <article class="image-text" :class="{ 'image-text--inverted': inverted }">
    <div class="image-text__text">
      <h3
        v-if="headline"
        v-html="headline"
        class="font_headline-montreal--secondary"
      ></h3>
      <p v-if="copy" v-html="copy"></p>
      <a
        v-if="link_target && link_label"
        :href="link_target"
        class="button button--secondary"
      >
        {{ link_label }}
      </a>
      <div v-if="arrow_image" class="image-text__arrow">
        <img :src="arrow_image" alt="Arrow to next step"/>
      </div>
    </div>
    <div v-if="image_src" class="image-text__image">
      <img
        v-if="image_src"
        :src="image_src"
        :alt="image_alt"
        :title="image_title"
      />
    </div>
  </article>
</template>
<script>
export default {
  name: "ImageTextModule",
  props: {
    image_src: {
      type: String,
    },
    image_alt: {
      type: String,
    },
    image_title: {
      type: String,
    },
    image_rounded: {
      type: Boolean,
    },
    headline: {
      type: String,
    },
    subline: {
      type: String,
    },
    copy: {
      type: String,
    },
    link_target: {
      type: String,
    },
    link_label: {
      type: String,
    },
    arrow_image: {
      type: String,
    },
    inverted: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
@import "@/scss/vars.scss";

.image-text {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  // @media (max-width: $breakpoint-m) {
  //   flex-direction: row-reverse;
  //   justify-content: flex-end;
  // }

  &:nth-of-type(even) {
    flex-direction: row-reverse;

    .image-text__text {
      justify-content: flex-start;
    }

    .image-text__arrow {
      right: auto;
      left: -180px;

      @media (max-width: $breakpoint-s) {
        width: 60px;
        bottom: -50px;
        left: auto;
        right: 100%;
        transform: rotate(-25deg);
      }
    }
  }

  &.image-text--inverted {
    flex-direction: row-reverse;

    &:nth-of-type(odd) {
      .image-text__arrow {
        right: auto;
        left: -180px;

        @media (max-width: $breakpoint-s) {
          width: 60px;
          bottom: -50px;
          left: auto;
          right: 100%;
          transform: rotate(-25deg);
        }
      }
    }

    &:nth-of-type(even) {
      flex-direction: row;

      // @media (max-width: $breakpoint-m) {
      //   flex-direction: row-reverse;
      //   justify-content: flex-end;
      // }
    }
  }

  > * {
    width: 46%;

    @media (max-width: $breakpoint-s) {
      width: 100%;
    }
  }

  .image-text__text {
    position: relative;
    display: flex;
    flex-wrap: wrap;

    // @media (max-width: $breakpoint-s) {
    //   width: 80%;
    // }
  }

  .image-text__image {
    img {
      width: 100%;
    }
  }

  .image-text__arrow {
    position: absolute;
    width: 120px;
    bottom: -30px;
    right: -180px;

    @media (max-width: $breakpoint-s) {
      width: 60px;
      bottom: -50px;
      left: 100%;
      right: auto;
      transform: rotate(25deg);
    }

    img {
      width: 100%;
      height: auto;
    }
  }

  .image-text__ratio-box {
    width: 100%;
    padding-top: 100%;
    position: relative;
    overflow: hidden;

    &.image-text__ratio-box--rounded {
      border-radius: 12px;
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &.image-text--keep-format {
    .image-text__text {
      @media (max-width: $breakpoint-s) {
        width: 80%;
      }
    }
  }
}
</style>
