<template>
  <main class="gespraeche-view background--skipped">
    <background-component :backgroundSkipped="true" />
    <section class="section">
      <intro v-bind="Intro_data" />
    </section>
    <section
      id="phasen-der-gespraeche"
      class="section section--overlay section--background-light headline--variant"
    >
      <h2>Phasen der Gespräche</h2>
      <image-text v-bind="ImageText_1_data" />
    </section>
    <section
      id="gespraechsvorlagen"
      class="section section--background-blue headline--variant"
    >
      <h2>Gesprächsvorlagen</h2>
      <image-text v-bind="ImageText_2_data" />
      <image-text v-bind="ImageText_3_data" />
      <image-full-width v-bind="ImageFullWidth_1_data" />
      <image-text v-bind="ImageText_4_data" />
    </section>
    <section class="section section--quote">
      <quote-component v-bind="Quote_1_data" />
    </section>
  </main>
</template>

<script>
import BackgroundComponent from "@/vue/components/Background.vue";
import ImageText from "@/vue/modules/ImageText.vue";
import QuoteComponent from "@/vue/modules/Quote.vue";
import Intro from "@/vue/modules/Intro.vue";
import ImageFullWidth from "@/vue/modules/ImageFullWidth.vue";

import Intro_data from "@/data/gespraeche/intro.json";
import ImageText_1_data from "@/data/gespraeche/image-text_1.json";
import ImageText_2_data from "@/data/gespraeche/image-text_2.json";
import ImageText_3_data from "@/data/gespraeche/image-text_3.json";
import ImageText_4_data from "@/data/gespraeche/image-text_4.json";
import ImageFullWidth_1_data from "@/data/gespraeche/image-full-width_1.json";
import Quote_1_data from "@/data/gespraeche/quote_1.json";

export default {
  name: "GespraecheView",
  components: {
    BackgroundComponent,
    QuoteComponent,
    ImageText,
    Intro,
    ImageFullWidth,
  },
  data() {
    return {
      Intro_data,
      ImageText_1_data,
      ImageText_2_data,
      ImageText_3_data,
      ImageText_4_data,
      ImageFullWidth_1_data,
      Quote_1_data,
    };
  },
};
</script>

<style lang="scss">
@import "@/scss/vars.scss";

</style>
