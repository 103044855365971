<template>
  <main class="glossar-view background--skipped">
    <background-component
      :backgroundSkipped="true"
      class="background--secondary"
    />

    <section
      class="section section--overlay section--background-light headline--variant"
    >
      <h2>Glossar</h2>
      <image-text v-bind="ImageText_1_data" />
      <image-text v-bind="ImageText_2_data" />
      <image-text v-bind="ImageText_3_data" />
      <image-text v-bind="ImageText_4_data" />
      <image-text v-bind="ImageText_5_data" />
      <image-text v-bind="ImageText_6_data" />
      <image-text v-bind="ImageText_7_data" />
      <image-text v-bind="ImageText_8_data" />
      <image-text v-bind="ImageText_9_data" />
      <image-text v-bind="ImageText_10_data" />
    </section>
  </main>
</template>

<script>
import BackgroundComponent from "@/vue/components/Background.vue";
import ImageText from "@/vue/modules/ImageText.vue";

import ImageText_1_data from "@/data/glossar/image-text_1.json";
import ImageText_2_data from "@/data/glossar/image-text_2.json";
import ImageText_3_data from "@/data/glossar/image-text_3.json";
import ImageText_4_data from "@/data/glossar/image-text_4.json";
import ImageText_5_data from "@/data/glossar/image-text_5.json";
import ImageText_6_data from "@/data/glossar/image-text_6.json";
import ImageText_7_data from "@/data/glossar/image-text_7.json";
import ImageText_8_data from "@/data/glossar/image-text_8.json";
import ImageText_9_data from "@/data/glossar/image-text_9.json";
import ImageText_10_data from "@/data/glossar/image-text_10.json";

export default {
  name: "GlossarView",

  components: {
    BackgroundComponent,
    ImageText,
  },

  data() {
    return {
      ImageText_1_data,
      ImageText_2_data,
      ImageText_3_data,
      ImageText_4_data,
      ImageText_5_data,
      ImageText_6_data,
      ImageText_7_data,
      ImageText_8_data,
      ImageText_9_data,
      ImageText_10_data,
    };
  },
};
</script>

<style lang="scss">
.glossar-view {
  padding: 200px 0;
}
</style>
